import { gql } from '@apollo/client';

export const REGISTER = gql`
    mutation newUser($input: UserInput) {
        newUser(input: $input) {
            username
            email
            password
        }
    }
`;


export const LOGIN = gql`
mutation authUser($input: AuthInput) {
    authUser(input: $input) {
        token
    }
}
`;  